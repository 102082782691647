import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/Status_LED/SuccessBox';
import StatusTable from 'components/Web_User_Interface/1080p_Series/Features/Status_LED/statusTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Status LED",
  "path": "/Web_User_Interface/1080p_Series/Features/Status_LED/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.",
  "image": "./WebUI_1080p_SearchThumb_Features_Status_LEDs.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Features_Status_LEDs.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Features Menu // Status LED' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.' image='/images/Search/WebUI_1080p_SearchThumb_Features_Status_LEDs.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Features_Status_LEDs.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Features/Status_LED/' locationFR='/fr/Web_User_Interface/1080p_Series/Features/Status_LED/' crumbLabel="Status LED" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><em parentName="p">{`(if available in your camera model)`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/f0811/1080p_Settings_Features_Status_LED.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0ElEQVQoz4WSS0wTQRjHBwoGaTBShaC1FQoFCm1IqYanvKTlndKKCJRHCxTkESPQndnd7mN2AfEgYgwGE2OMF/Si8eDBC0pQY0yMetSbiYiJhogmiIB1zW45qBd/mcxlvl/+/y8ZACFEChRFBykYEC8PXXk6NLvUO7PYo5zumUX/zKMzV58Fpq4xFMkwnM/nyzJbrLk2QEAEEQkhGh8fd1RWtnV4P3+X3n1aX/6yvfI19GEttLwWer+6+XFdunNrHnV0jLZ3eevczvyiY9kWMAZRgEAQwnOTkwUFxc7GJkmStrd+SAq/lHt1Y3v2+caD+ZvXRwem/HTQ6UFVpQ05NgARSUASITm5yl5BB4YlSQqFQptbPyVJeruy/vDNt+XHL9YWntyduzHS0Dnd77/oP1tX1mm15AECkQGICAixILrqq3KN2vzCcsrrPj/S62tuDbS3dnn6/Z7Tww1Ob039guB9xZe9vOS9zZIDFTZAkhSh7CyKosNRA2QiNDEqxuMozMh02dJ6HDa9Tn8wSdvdWEF3VTRb06fdKUuD+vu+bID+kKuqqwEAu6JVAKjKU+PmenPuCeWUMztZd6jBbvcP9BnSLemGrMQErUWrQQUJQOks76zIcrIqKgoAUGJUQ5fhwqkMb0n+kZQkOjDS2daSrNWlpRnj4/fuUe8+nqIGFEWRFBWW7Q67XDoy0poIbLpoACJq0/e9bk4cNGtKG12morzUjFRtii5WHRO7K6JYq5JlSpExxk0n3HHx+2tsOr/9sFpzwJxm4E0mxmiotJiO1pa4hlrcfSfz6ootuZYMc6Y+1bAjh2FZluf5CYGXwbzI40kOExxPshzHsALGE5MTWMAYY57nBcz/JXMcJ4oCxgIWZOQhQRCFHTAWFEfWw6+Apimaomh6J1nAmMf/gQ83wxiMEigA0Rgh/3CGYYIMG2RYlmU5hfDQPybHcSzLBoPB38BbZgFIcargAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/e4706/1080p_Settings_Features_Status_LED.avif 230w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/d1af7/1080p_Settings_Features_Status_LED.avif 460w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/7f308/1080p_Settings_Features_Status_LED.avif 920w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/16517/1080p_Settings_Features_Status_LED.avif 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/a0b58/1080p_Settings_Features_Status_LED.webp 230w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/bc10c/1080p_Settings_Features_Status_LED.webp 460w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/966d8/1080p_Settings_Features_Status_LED.webp 920w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/2c416/1080p_Settings_Features_Status_LED.webp 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/81c8e/1080p_Settings_Features_Status_LED.png 230w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/08a84/1080p_Settings_Features_Status_LED.png 460w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/c0255/1080p_Settings_Features_Status_LED.png 920w", "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/f0811/1080p_Settings_Features_Status_LED.png 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e3802a85f3b23858c5b210fbd5bd2ab/c0255/1080p_Settings_Features_Status_LED.png",
              "alt": "Web User Interface - 1080p Series - Features Status LED",
              "title": "Web User Interface - 1080p Series - Features Status LED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <StatusTable mdxType="StatusTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can adjust the behaviour of the red Power Status LED and blue WiFi Status LED of the camera. Enable it to have it display camera activity - which is especially helpful during the installation process. Or deactivate it for normal operation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      